import "./App.css";
import Header from "./components/Header/header";
import VideoLink from "./components/Videolink/videolink";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import Images from "./pages/Images/Images"
import Advert from './pages/Advert/Advert'
import Channel from './pages/Channel/Channel'
import ScoreCard from './pages/ScoreCard/ScoreCard'
import ChannelManagement from "./pages/ChannelManagement/ChannelManagement";
import UserForm from "./pages/ServiceRequestForm/ServiceRequestForm";
import { authenticate } from "./AuthService";
import Login from "./components/Login";
import { useEffect, useState } from "react";
import OAuthCallback from "./components/OauthCallback";

function App() {

  const [authenticated, setAuthenticated] = useState(localStorage.getItem('authenticated') === 'true');
  const [loggedInUser, setLoggedInUser] = useState('');
  // const navigate = useNavigate();

  useEffect(() => {
    const storedAuth = localStorage.getItem('authenticated');
    if (storedAuth) {
      setAuthenticated(true);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    setAuthenticated(false);
  };

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route
            path="/login"
            element={<Login setAuthenticated={setAuthenticated} />}
          />
          <Route exact path="/" element={<VideoLink />}></Route>
          <Route path="/logo" element={<Images />} />
          <Route path="/advert" element={<Advert />} />
          <Route path="/channel" element={<Channel />} />
          <Route path="/scorecard" element={<ScoreCard />} />
          <Route path="/manage" element={<ChannelManagement />} />
          {/* <Route path="/oauth-callback" element={<OAuthCallback onLogin={handleLogin} />} /> */}
          {/* <Route path="/requestform" element={<UserForm />} /> */}
          {/* {localStorage.getItem('authenticated') === 'true' && (
            <>
              <Route path="/requestform" element={<UserForm />} />
            </>
          )} */}
          <Route path="/requestform" element={authenticated ? <UserForm authenticated={authenticated} handleLogout={handleLogout} /> : <Login setAuthenticated={setAuthenticated} />}
          />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
