import React, { useState, useEffect } from 'react';
import styles from './ChannelManagement.module.css'
import StopIcon from '../../components/Icons/StopIcon';
import StreamIcon from '../../components/Icons/StreamIcon';
import UploadIcon from '../../components/Icons/UploadIcon';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import { Toast } from 'react-bootstrap';
import ReactModal from 'react-modal';
import Spinner from 'react-bootstrap/Spinner';

const ChannelManagement = () => {
    const search = useLocation().search;
    const uid = new URLSearchParams(search).get("user_id");
    const slotId = new URLSearchParams(search).get("slot_id");
    const navigate = useNavigate();

    let mediaUrl = process.env.REACT_APP_URL_SCP;
    let streamUrl = process.env.REACT_APP_URL_FM;

    const [timeWarning, setTimeWarning] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpModalShow, setOtpModalShow] = useState(false);
    const [otpModalShowStop, setOtpModalShowStop] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [toastType, setToastType] = useState('success');
    const [toastMessage, setToastMessage] = useState('');
    const [stopPrompt, setStopPrompt] = useState(false);
    const [uploadModalShow, setUploadModalShow] = useState(false);
    const [file, setFile] = useState('');
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const storedCombo = sessionStorage.getItem('uidSlotIdCombo');
        if (storedCombo === `${uid}-${slotId}`) {
        } else {
            navigate(`/?user_id=${uid}&slot_id=${slotId}`);
        }
    }, []);

    const onStartClick = () => {
        // setTimeWarning(true)
        // setOtpModalShow(true)
        setIsLoading(true)
        axios
            .get(mediaUrl + "/channel/check?user_id=" + uid + "&slot_id=" + slotId)
            .then((res) => {
                setIsLoading(false)
                console.log(res.data)
                if (res.data.statusCode === 201) {
                    setTimeWarning(true)

                }
                else if (res.data.statusCode === 200) {
                    setOtpModalShow(true)
                    //otp generation api
                    axios
                        .get(mediaUrl + "/sendtoken?user_id=" + uid + "&slot_id=" + slotId + "&process=starting")
                        .then((res) => {

                            console.log(res.data)
                            if (res.data.HTTP_CODE === 200) {


                            }
                            else {


                            }
                        });

                    //otp generation api end

                }
                else if (res.data.statusCode === 202) {
                    showAndCloseToast('info', res.data.Message);
                }
                else if (res.data.statusCode === 203) {
                    showAndCloseToast('fail', res.data.e.message);
                }
            });
    }
    const onStopClick = () => {
        setIsLoading(true)

        axios
            .get(mediaUrl + "/channel/check?user_id=" + uid + "&slot_id=" + slotId + "&process=stop")
            .then((res) => {
                setIsLoading(false)
                console.log(res.data)
                if (res.data.statusCode === 201) {


                }
                else if (res.data.statusCode === 200) {
                    setStopPrompt(true)


                }
                else if (res.data.statusCode === 202) {
                    showAndCloseToast('info', res.data.Message);
                }
            });


    }

    const onUploadClick = () => {

        setUploadModalShow(true)

    }

    const onGoHome = () => {

        // navigate('/');
        navigate(`/?user_id=${uid}&slot_id=${slotId}`);
    }
    const handleCloseTimeWarning = () => {

        setTimeWarning(false)
    }

    const onChangeOtp = (event) => {
        setOtp(event.target.value)
    }
    const handleCloseOtpModal = () => {
        setOtpModalShow(false)
    }

    const onYesTimeWarning = () => {
        setIsLoading(true)
        setOtpModalShow(true)
        setTimeWarning(false)
        //otp generation api
        axios
            .get(mediaUrl + "/sendtoken?user_id=" + uid + "&slot_id=" + slotId + "&process=starting")
            .then((res) => {
                setIsLoading(false)
                console.log(res.data)
                if (res.data.HTTP_CODE === 200) {


                }
                else {


                }
            });
        //otp generation api end
    }

    const onSubmitOtpStart = () => {
        setIsLoading(true)
        axios
            .post(mediaUrl + "/verifytoken?user_id=" + uid + "&slot_id=" + slotId, {
                auth: {
                    otp: otp,
                },
            })
            .then((res) => {

                console.log("response data: " + res.data);
                if (res.data.statusCode === 200) {
                    //start api call

                    axios.post(mediaUrl + "/invokestreamstart", {
                        user_id: uid,
                        slot_id: slotId,
                        operation: "startChannel"
                    })
                        .then((res) => {
                            setIsLoading(false)
                            setOtpModalShow(false)
                            console.log(res.data)
                            showAndCloseToast('success', 'Channel will be starting soon..');
                        });

                    //end start api call

                }
            })

    }
    const handleCloseOtpModalStop = () => {
        setOtpModalShowStop(false)
    }
    const onSubmitOtpStop = () => {
        setIsLoading(true);

        axios.get(`${streamUrl}/kioskLivestreamingRequest?Slot_Id=${slotId}`).then(res => {
            console.log('res : ', res);
            if (res?.data?.length > 0) {
                stopChannelAndStream(res.data[0].Channel_Id);
            } else {
                stopChannel();
            }
        })

    }


    const stopChannel = () => {
        axios
            .post(mediaUrl + "/verifytoken?user_id=" + uid + "&slot_id=" + slotId, {
                auth: {
                    otp: otp,
                },
            })
            .then((res) => {

                console.log("response data: " + res.data);
                if (res.data.statusCode === 200) {
                    //stop api call

                    axios
                        .get(mediaUrl + "/stopchannel?user_id=" + uid + "&slot_id=" + slotId + "&operation=" + "stopChannel")
                        .then((res) => {
                            setIsLoading(false)
                            setOtpModalShowStop(false)
                            console.log(res.data)
                            showAndCloseToast('success', 'Channel is stopping...');

                        });

                    //end stop api call

                }
            })
    }

    const stopChannelAndStream = (channelId) => {
        axios(`https://uhkvo1df8g.execute-api.us-east-2.amazonaws.com/dev/stop?channel=${channelId}`).then(res => {
            console.log('res:', res);
        })
    }

    const handleCloseToast = () => {
        setShowToast(false);
    };

    const showAndCloseToast = (type, message) => {
        setToastType(type);
        setToastMessage(message);
        setShowToast(true);
        setTimeout(() => {
            setShowToast(false);
        }, 6000); // Adjust the delay (in milliseconds) to your desired duration
    };

    const handleCloseStopPrompt = () => {
        setStopPrompt(false)
    }

    const onYesStopPrompt = () => {
        setStopPrompt(false)
        setOtpModalShowStop(true)
        //otp generation api
        axios
            .get(mediaUrl + "/sendtoken?user_id=" + uid + "&slot_id=" + slotId + "&process=stopping")
            .then((res) => {

                console.log(res.data)

            });

        //otp generation api end

    }

    const uploadModalClose = () => {
        setUploadModalShow(false)
    }

    const onSubmitVideo = () => {
        setIsLoading(true)
        console.log(file)
        //get presigned url
        axios
            .get(mediaUrl + "/mp4/url?user_id=" + uid + "&slot_id=" + slotId)
            .then((res) => {
                console.log(res)
                // upload file to s3
                axios
                    .put(res.data.signed_url, file, {
                        headers: {
                            'Content-Type': 'video/mp4',
                        }
                    })
                    .then((res) => {
                        setIsLoading(false)
                        showAndCloseToast('success', 'Uploaded video successfully');
                    })

            });

        uploadModalClose()
    }

    const onFileSelect = (event) => {
        console.log(event.target.files[0])
        setFile(event.target.files[0])
    }
    if (isLoading)
        return (
            <>
                <div style={{ marginTop: '40px' }} className="d-flex justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </>
        )
    else return (
        <div className="container">
            <div className="d-flex bd-highlight">
                <div className="container">

                    <div style={{ marginTop: '40px' }} className="d-flex justify-content-center">
                        <span style={{ color: "#b6860d" }}>
                            LIVE STREAMING CHANNEL MANAGEMENT
                        </span>
                    </div>

                    <div style={{ marginTop: '40px', marginBottom: '30px', position: 'fixed', top: '0', left: '50%', transform: 'translateX(-50%)', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} className="d-flex justify-content-center">
                        <Toast show={showToast} onClose={handleCloseToast}>
                            <Toast.Header
                                style={{
                                    background: toastType === 'success' ? '#47b354' : toastType === 'fail' ? 'red' : '#fac446',
                                }}
                            >
                                <strong style={{ color: 'white' }} className="me-auto">
                                    {toastType === 'success' ? 'Success' : toastType === 'fail' ? 'Fail' : 'Info'}
                                </strong>
                            </Toast.Header>
                            <Toast.Body
                                style={{ color: toastType === 'success' ? '#47b354' : toastType === 'fail' ? 'red' : '#a87b11' }}
                            >
                                {toastMessage}
                            </Toast.Body>
                        </Toast>
                    </div>

                    {!uploadModalShow && <div style={{ marginTop: '40px' }} className="d-flex flex-row justify-content-center mx-1">
                        <div className="col-md-4 col-lg-4 d-flex justify-content-center">

                            <button
                                name="start"

                                onClick={onStartClick}
                                type="button"
                            >
                                <StreamIcon />
                                <span>Start</span>
                            </button>

                            <button
                                name="stop"

                                onClick={onStopClick}
                                type="button"
                            >
                                <StopIcon />
                                <span>Stop</span>
                            </button>

                        </div>
                    </div>}

                    {!uploadModalShow && <div className="d-flex flex-row justify-content-center mx-1">
                        <div className="col-md-4 col-lg-4 d-flex justify-content-center">

                            <button name="video" type="button" onClick={onUploadClick}>
                                <UploadIcon />
                                <span>
                                    Upload
                                </span>
                            </button>

                        </div>
                    </div>}

                    {!uploadModalShow && <div style={{ marginTop: '40px' }} className="d-flex flex-row justify-content-center mx-1">
                        <div className="col-md-4 col-lg-4 d-flex justify-content-center">

                            <a style={{ cursor: 'pointer' }} onClick={onGoHome}>Home</a>

                        </div>
                    </div>}
                </div>

            </div>

            <Modal show={timeWarning} onHide={handleCloseTimeWarning}>
                <Modal.Body>
                    <div>
                        <label style={{ color: 'black' }}>Do you want to start the stream although it's scheduled for Later</label>
                    </div>
                    <div>
                        <button className="btn btn-warning" onClick={handleCloseTimeWarning}>
                            No
                        </button>
                        <button className="btn btn-warning" onClick={onYesTimeWarning}>
                            Yes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={otpModalShow} onHide={handleCloseOtpModal}>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword" >
                            <Form.Label style={{ color: 'black' }}>Enter the OTP send to your mail</Form.Label>
                            <Form.Control type="password" placeholder="Enter the OTP" onChange={onChangeOtp} />
                        </Form.Group>
                        <Button variant="warning" onClick={onSubmitOtpStart}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>


            <Modal show={otpModalShowStop} onHide={handleCloseOtpModalStop}>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPassword" >
                            <Form.Label style={{ color: 'black' }}>Enter the OTP send to your mail</Form.Label>
                            <Form.Control type="password" placeholder="Enter the OTP" onChange={onChangeOtp} />
                        </Form.Group>
                        <Button variant="warning" onClick={onSubmitOtpStop}>
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal show={stopPrompt} onHide={handleCloseStopPrompt}>
                <Modal.Body>
                    <div style={{ height: '50px' }}>
                        <label style={{ color: 'black' }}>Do you want to stop the stream?</label>
                    </div>
                    <div>
                        <button className="btn btn-warning" onClick={handleCloseStopPrompt}>
                            No
                        </button>
                        <button className="btn btn-warning" onClick={onYesStopPrompt}>
                            Yes
                        </button>
                    </div>
                </Modal.Body>
            </Modal>

            <ReactModal
                id="upload"
                isOpen={uploadModalShow}
                onRequestClose={uploadModalClose}
                ariaHideApp={false}
                style={{
                    overlay: {
                        position: "static",
                        backgroundColor: "rgba(0,0,0,0)",
                    },
                    content: {
                        background: "rgba(0,0,0,0)",
                        border: "0px",
                        position: "absolute",
                        top: "35%",
                        left: "50%",
                        transform: "translateX(-50%)",
                        bottom: "0px",
                        borderRadius: "0px",
                        width: "100%",
                        maxWidth: "400px",
                    },
                }}
            >
                <div>
                    <input
                        style={{ margin: '10px' }}
                        type="file"
                        onChange={onFileSelect}
                    />

                    <Button style={{ marginLeft: '0px' }} variant="warning" onClick={onSubmitVideo}>
                        Submit
                    </Button>
                    <Button variant="warning" onClick={uploadModalClose}>
                        Cancel
                    </Button>

                </div>
            </ReactModal>


        </div>

    );
};

export default ChannelManagement;