import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './login.css';
import { authenticate } from '../../AuthService';
import { jwtDecode } from 'jwt-decode';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [pageParams, setPageParams] = useSearchParams();
    const [authenticated, setAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // handleLogin(username, password);
        authenticate(username, password, onLoginSuccess, onLoginError);
        // setLoggedInUser(username);
        localStorage.setItem('loggedInUser', username);
    };

    const onLoginSuccess = async (result) => {
        console.log('Login successful', result);
        setAuthenticated(true);
        localStorage.setItem('authenticated', 'true');
        navigate('/requestform?channel=' + pageParams.get('channel'));
        window.location.reload();
    };

    const onLoginError = (err) => {
        console.error('Login error', err);
        if (err.code === 'NotAuthorizedException') {
            setErrorMessage('Incorrect username or password. Please try again.');
        } else {
            setErrorMessage('An error occurred during login. Please try again.');
        }
    };

    const loginWithAuthProvider = (authType) => {
        const domainName = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
        let URL = '';
        if (authType === 'google') {
            const googleAuthURL = process.env.REACT_APP_URL_GOOGLE_AUTH;
            URL = `${googleAuthURL}&redirect_uri=${encodeURIComponent(domainName + '/requestform')}&state=STATE&scope=aws.cognito.signin.user.admin+openid+email+profile+phone&identity_provider=Google`;
        }

        const popup = window.open(URL, 'My Popup', 'width=400,height=450');

        // Polling to check if the popup is redirected to the desired URL
        const popupInterval = setInterval(() => {
            try {
                if (popup.location.href.includes('requestform')) {
                    clearInterval(popupInterval);

                    const urlParams = new URLSearchParams(popup.location.hash);
                    const tokenInfo = {
                        accessToken: urlParams.get('access_token'),
                        idToken: urlParams.get('id_token'),
                    };

                    if (tokenInfo.idToken) {
                        // Decode the ID token to get user details
                        const decodedToken = jwtDecode(tokenInfo.idToken);
                        const cognitoUsername = decodedToken['cognito:username'];
                        console.log('Decoded Token:', decodedToken);

                        // Store user information in localStorage or state
                        localStorage.setItem('userToken', JSON.stringify(tokenInfo));
                        localStorage.setItem('userInfo', JSON.stringify(decodedToken));
                        localStorage.setItem('loggedInUser', cognitoUsername);
                        localStorage.setItem('authenticated', 'true');

                        console.log('User Info:', decodedToken);

                        // Navigate to the requestform page
                        navigate('/requestform?channel=' + pageParams.get('channel'));
                        window.location.reload();
                    }
                    popup.close();
                }
            } catch (error) {
                // Handle any errors
            }

            if (popup.closed) {
                clearInterval(popupInterval);
                console.log('Popup closed by user.');
            }
        }, 500);
    };

    return (
        <div className="login-container">
            <div className="login-card">
                {/* <div className="login-header">
                    <img src={logo} alt="Fields Manager Logo" className="logo" />
                    <h1>FIELDS MANAGER</h1>
                </div> */}
                <form onSubmit={handleSubmit} className="login-form">
                    {errorMessage && <div className="error-message">{errorMessage}</div>}
                    <button
                        type="button"
                        className="google-sign-in"
                        onClick={() => loginWithAuthProvider('google')}
                    >
                        Sign in with Google
                    </button>
                    <div className="divider">
                        <hr className="line" />
                        <span>OR</span>
                        <hr className="line" />
                    </div>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        placeholder="Username or Email"
                        className="login-input-field"
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Password"
                        className="login-input-field"
                    />
                    <button type="submit" className="login-submit-btn">SIGN IN</button>
                </form>
            </div>
        </div>
    );
};

export default Login;