import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../ServiceRequestForm/ServiceRequestForm.css';
import { useSearchParams } from 'react-router-dom';
import Login from '../../components/Login';

const UserForm = ({ authenticated, handleLogout, setAuthenticated }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        park: '',
        ground: '',
        sport: '',
        city: '',
        date: new Date(),
        startTime: '09:00 AM',
        endTime: '05:00 PM',
        title: '',
        description: ''
    });

    const [parks, setParks] = useState([]);
    const [grounds, setGrounds] = useState([]);
    const [sports, setSports] = useState([]);
    const [slotId, setSlotId] = useState('');
    const [serviceRequestId, setServiceRequestId] = useState('');
    const [pageParams, setPageParams] = useSearchParams();
    const [message, setMessage] = useState('');
    // const [isChannelRunning, setIsChannelRunning] = useState(false);
    const [loading, setLoading] = useState(false);

    const [errors, setErrors] = useState({
        email: '',
        phone: '',
    });

    useEffect(() => {
        // Fetch parks data
        axios.get(`${process.env.REACT_APP_URL_FACILITY}/parks/get`, {
            params: {
                User_Id: localStorage.getItem('User_Id')
            }
        })
            .then(response => {
                console.log('Parks API response:', response.data);

                // Filter out any parks that are null or have essential properties set to null
                const filteredParks = response.data.filter(park =>
                    park && park.Park_Id && park.Park_Name
                );

                setParks(filteredParks);
            })
            .catch(error => console.error('Error fetching parks:', error));
        const loggedInUser = localStorage.getItem('loggedInUser');
        fetchAndStoreUserId(loggedInUser)
    }, []);

    const fetchAndStoreUserId = async (username) => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL_SLOTS + `/users?Username=${username}`);
            console.log('response', response.data[0]);
            if (response.data && response.data[0].User_Id) {
                localStorage.setItem('User_Id', response.data[0].User_Id);
                console.log('User_Id fetched and stored:', response.data[0].User_Id);
            }
        } catch (error) {
            console.error('Error fetching User_Id:', error);
        }
    };

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
            }, 10000);

            return () => clearTimeout(timer);
        }
    }, [message]);

    const formatTime = (time) => {
        const [hour, minute] = time.split(':');
        const hours = parseInt(hour, 10);
        const suffix = hours >= 12 ? 'PM' : 'AM';
        const formattedHour = ((hours + 11) % 12 + 1);
        return `${formattedHour}:${minute} ${suffix}`;
    };

    const handleParkChange = (e) => {
        const selectedParkId = e.target.value;
        const selectedPark = parks.find(park => park.Park_Id === parseInt(selectedParkId));
        console.log("selectedPark", selectedPark);

        setFormData({
            ...formData,
            park: selectedParkId,
            parkName: selectedPark.Park_Name,
            ground: '',
            sport: '',
            city: selectedPark.City_Id,
            cityName: selectedPark.City
        });

        // Fetch grounds data based on selected park
        axios.get(`${process.env.REACT_APP_URL_FACILITY}/grounds/bypark?User_Id=${localStorage.getItem('User_Id')}&Park_Id=${selectedParkId}`)
            .then(response => setGrounds(response.data))
            .catch(error => console.error('Error fetching grounds:', error));
    };

    const handleGroundChange = (e) => {
        const selectedGroundId = e.target.value;
        const selectedGround = grounds.find(ground => ground.Ground_Id === parseInt(selectedGroundId));
        console.log("selectedGround", selectedGround);

        setFormData({
            ...formData,
            ground: selectedGroundId,
            groundName: selectedGround.Ground_Name,
            sport: '',
        });

        // Fetch sports data based on selected park and ground
        axios.get(process.env.REACT_APP_URL_API + `/sports/getbyparkandground?Park_Id=${formData.park}&Ground_Id=${selectedGroundId}`)
            .then(response => setSports(response.data))
            .catch(error => console.error('Error fetching sports:', error));
    };

    const handleSportChange = (e) => {
        const selectedSportId = e.target.value;
        const selectedSport = sports.find(sport => sport.Sport_Id === parseInt(selectedSportId));
        console.log("selectedSport", selectedSport);

        setFormData({
            ...formData,
            sport: selectedSportId,
            sportName: selectedSport.Sport_Name,
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (name === 'email') {
            setErrors({
                ...errors,
                email: '',
            });
        } else if (name === 'phone') {
            setErrors({
                ...errors,
                phone: '',
            });
        }
    };

    const handleDateChange = (date) => {
        setFormData({
            ...formData,
            date: date,
        });
    };

    const validateForm = () => {
        let isValid = true;
        let errorMessage = '';

        // Email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            errorMessage = 'Please enter a valid email address.';
            isValid = false;
        }

        // Phone number validation
        const phonePattern = /^[0-9]{10}$/;
        if (!phonePattern.test(formData.phone)) {
            errorMessage = 'Please enter a valid 10-digit phone number.';
            isValid = false;
        }

        // Check if all other required fields are filled
        const requiredFields = ['firstName', 'lastName', 'park', 'ground', 'sport', 'city', 'date', 'title', 'description'];
        const isAllFilled = requiredFields.every(field => formData[field]);

        if (!isAllFilled) {
            errorMessage = 'Please fill all the required fields.';
            isValid = false;
        }

        if (!isValid) {
            setMessage(errorMessage);
        }

        return isValid;
    };
    const checkChannelStatus = async (channelId) => {
        try {
            const response = await axios.get(process.env.REACT_APP_URL_ONETOUCH + `/channelStatus`, {
                params: { channelId: channelId }
            });
            return response.data.body.status;
        } catch (error) {
            console.error('Error checking channel status:', error);
            throw new Error('Error checking channel status.');
        }
    };

    const submitSlot = async () => {
        const formattedStartTime = formatTime(formData.startTime);
        const formattedEndTime = formatTime(formData.endTime);
        const slot = `${formattedStartTime} - ${formattedEndTime}`;
        const formattedDate = formData.date.toISOString().split('T')[0];

        const queryParams = new URLSearchParams({
            City_Id: formData.city,
            Park_Id: formData.park,
            Ground_Id: formData.ground,
            Sport_Id: formData.sport,
            Slot: slot,
            Date: formattedDate,
            User_Id: localStorage.getItem('User_Id'),
            onBoardSlotComments: '',
            RequestliveStreaming: 16,
            Requested_Quals: JSON.stringify([]),
            Service_Ids: JSON.stringify([16]),
            Preferred_Price: 0,
            Fixed_Pay: false,
            selectedTeams: 'undefined',
            Extra_Recipient: '',
            Resident_Of: 'null'
        }).toString();

        const response = await axios.post(process.env.REACT_APP_URL_SLOTS + `/slots/onboard?${queryParams}`);
        setSlotId(response.data.Slot_Id);
        return response.data;
    };

    const scheduleStream = async () => {
        const streamParams = new URLSearchParams({
            channel: pageParams.get('channel'),
            title: formData.title,
            description: formData.description
        }).toString();

        const response = await axios.get(process.env.REACT_APP_URL_ONETOUCH + `/start?${streamParams}`);
        return response.data;
    };

    const getServiceRequest = async (slotId) => {
        const response = await axios.get(process.env.REACT_APP_URL_SLOTS + '/slots/serviceRequests/byProvider?User_Id=539');
        const serviceRequest = response.data.find(item => item.Slot_Id === slotId);
        setServiceRequestId(serviceRequest.Service_Request_Id);
        return serviceRequest.Service_Request_Id;
    };

    const submitBid = async (serviceRequestId, streamKey, streamUrl) => {
        const bidsParams = new URLSearchParams({
            ServiceProvider_Id: 539,
            Service_Request_Id: serviceRequestId,
            Bid_Amount: 0,
            Comments: '',
            Channel_ID: pageParams.get('channel'),
            Channel_Stream_Url: streamUrl,
            Stream_Key: streamKey,
            Secondary_Stream_Key: 'undefined',
            Secondary_Stream_Url: 'undefined',
            Stream_Creation: 0,
            Extra_Recipient: ''
        }).toString();

        await axios.post(process.env.REACT_APP_URL_SLOTS + `/slots/serviceRequests/bids?${bidsParams}`);
    };

    const acceptBid = async (serviceRequestId, slotId, streamKey, streamUrl) => {
        const acceptParams = new URLSearchParams({
            ServiceProvider_Id: 539,
            Service_Request_Id: serviceRequestId,
            User_Id: localStorage.getItem('User_Id'),
            Slot_Id: slotId,
            Channel_ID: pageParams.get('channel'),
            Stream_Creation: 0,
            Stream_Key: streamKey,
            Channel_Stream_Url: streamUrl,
            Service_Id: 16
        }).toString();

        await axios.post(process.env.REACT_APP_URL_SLOTS + `/slots/serviceRequests/bids/accept?${acceptParams}`);
    };

    const updateConfigFile = async (serviceRequestId, slotId, streamKey, streamUrl) => {
        const updateConfigParams = new URLSearchParams({
            ServiceProvider_Id: 539,
            Service_Request_Id: serviceRequestId,
            User_Id: localStorage.getItem('User_Id'),
            Slot_Id: slotId,
            Channel_ID: pageParams.get('channel'),
            Stream_Creation: 0,
            Stream_Key: streamKey,
            Channel_Stream_Url: streamUrl,
            Service_Id: 16,
            Secondary_Stream_Url: 'undefined',
            Secondary_Stream_Key: 'undefined',
            User_Email: formData.email
        }).toString();

        await axios.post(process.env.REACT_APP_URL_SCP + `/updateConfigFile?${updateConfigParams}`);
    };

    const submitLiveStreamingRequest = async (slotId) => {
        const formattedDate = formData.date.toISOString().split('T')[0];
        const requestData = {
            First_Name: formData.firstName,
            Last_Name: formData.lastName,
            Email: formData.email,
            Phone: formData.phone,
            City_Id: formData.city,
            City: formData.cityName,
            Park_Id: formData.park,
            Park: formData.parkName,
            Ground_Id: formData.ground,
            Ground: formData.groundName,
            Sport_Id: formData.sport,
            Sport: formData.sportName,
            Slot_Id: slotId,
            Date: formattedDate,
            Start_Time: formData.startTime,
            End_Time: formData.endTime,
            Title: formData.title,
            Description: formData.description,
            Channel_Id: pageParams.get('channel')
        };

        const response = await axios.post(process.env.REACT_APP_URL_SLOTS + '/kioskLivestreamingRequest', requestData);

        if (response.data.message === 'Input error') {
            setMessage('Error in input data for live streaming service request.');
        } else {
            setMessage('Form submitted successfully! Please check your email');
            setLoading(false);
        }
    };


    const handleStreamForm = async (e) => {
        e.preventDefault();

        if (!validateForm()) return;
        setLoading(true);

        try {
            const channelId = pageParams.get('channel');
            const channelStatus = await checkChannelStatus(channelId);

            if (channelStatus === 'RUNNING') {
                setMessage('Channel is already running! You cannot submit the form.');
                setLoading(false);
                return;
            }
            const slotRes = await submitSlot();
            const slotId = slotRes?.message === 'overlap' ? null : slotRes?.Slot_Id;
            if (slotId) {
                const streamData = await scheduleStream();
                const { video_link: streamUrl, streamKey } = streamData;
                const serviceRequestId = await getServiceRequest(slotId);

                await submitBid(serviceRequestId, streamKey, streamUrl);
                await acceptBid(serviceRequestId, slotId, streamKey, streamUrl);
                await updateConfigFile(serviceRequestId, slotId, streamKey, streamUrl);
                await submitLiveStreamingRequest(slotId);

                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    park: '',
                    ground: '',
                    sport: '',
                    city: '',
                    date: new Date(),
                    startTime: '09:00 AM',
                    endTime: '05:00 PM',
                    title: '',
                    description: '',
                });
            } else {
                setMessage('Slot already exists.');
                setLoading(false);
            }

        } catch (error) {
            console.error('Error submitting form:', error);
            setMessage('Error submitting the form.');
            setLoading(false);
        }
    };

    if (!authenticated) {
        return <Login setAuthenticated={setAuthenticated} />;
    }

    return (
        <div className="user-form-container" style={{ height: '90vh' }}>
            {authenticated && (
                <div className="requestform-logout-button">
                    <span onClick={handleLogout} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline', marginRight: '10px'}}>
                        Logout
                    </span>
                </div>
            )}
            {loading && (
                <>
                    <div className="loading-overlay"></div>
                    <div className="loading">
                        <div className="loader-spinner"></div>
                        We are processing your request...
                    </div>
                </>
            )}
            {message && <div className={`message ${message.includes('Error') ? 'error-message' : 'success-message'}`}>{message}</div>}
            <div className="user-form-card" style={{ height: '80vh' }}>
                <div className='user-form-heading'>Live Streaming Request</div>
                <form onSubmit={handleStreamForm} className="user-form" style={{ height: '70vh', overflowY: 'auto' }}>
                    <div className="user-form-group">
                        <label htmlFor="firstName" className="user-form-label">First Name:<span className="required-asterisk">*</span></label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={formData.firstName}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="lastName" className="user-form-label">Last Name: <span className="required-asterisk">*</span></label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={formData.lastName}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="email" className="user-form-label">Email: <span className="required-asterisk">*</span></label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                        {errors.email && <span className="validation-message">{errors.email}</span>}
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="phone" className="user-form-label">Phone Number: <span className="required-asterisk">*</span></label>
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                        {errors.phone && <span className="validation-message">{errors.phone}</span>}
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="park" className="user-form-label">Park: <span className="required-asterisk">*</span></label>
                        <select
                            id="park"
                            name="park"
                            value={formData.park}
                            onChange={handleParkChange}
                            className="user-form-input"
                        >
                            <option value="">Select a Park</option>
                            {parks.map(park => (
                                <option key={park.Park_Id} value={`${park.Park_Id}`}>
                                    {park.Park_Name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="ground" className="user-form-label">Ground: <span className="required-asterisk">*</span></label>
                        <select
                            id="ground"
                            name="ground"
                            value={formData.ground}
                            onChange={handleGroundChange}
                            className="user-form-input"
                        >
                            <option value="">Select a Ground</option>
                            {grounds.map(ground => (
                                <option key={ground.Ground_Id} value={`${ground.Ground_Id}`}>
                                    {ground.Ground_Name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="sport" className="user-form-label">Sport: <span className="required-asterisk">*</span></label>
                        <select
                            id="sport"
                            name="sport"
                            value={formData.sport}
                            onChange={handleSportChange}
                            className="user-form-input"
                        >
                            <option value="">Select a Sport</option>
                            {sports.map(sport => (
                                <option key={sport.Sport_Id} value={`${sport.Sport_Id}`}>
                                    {sport.Sport_Name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="date" className="user-form-label">Date:</label>
                        <DatePicker
                            selected={formData.date}
                            onChange={handleDateChange}
                            minDate={new Date()}
                            dateFormat="yyyy-MM-dd"
                            className="user-form-input"
                            wrapperClassName="custom-date-picker"
                            disabled
                        />
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="startTime" className="user-form-label">Start Time:  <span className="required-asterisk">*</span></label>
                        <input
                            type="time"
                            id="startTime"
                            name="startTime"
                            value={formData.startTime}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="endTime" className="user-form-label">End Time: <span className="required-asterisk">*</span></label>
                        <input
                            type="time"
                            id="endTime"
                            name="endTime"
                            value={formData.endTime}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="title" className="user-form-label">Title: <span className="required-asterisk">*</span></label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                    </div>
                    <div className="user-form-group">
                        <label htmlFor="description" className="user-form-label">Description: <span className="required-asterisk">*</span></label>
                        <input
                            type="text"
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="user-form-input"
                        />
                    </div>
                    <button type="submit" className="user-form-button">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default UserForm;
