// AuthService.js
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

export const authenticate = (username, password, onSuccess, onError) => {
    console.log('UserLoginService: starting the authentication');

    const authenticationData = {
        Username: username,
        Password: password,
    };

    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID,
    };

    const userPool = new CognitoUserPool(poolData);

    const userData = {
        Username: username,
        Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
        newPasswordRequired: (userAttributes, requiredAttributes) => onError('User needs to set password.'),
        onSuccess: result => onSuccess(result),
        onFailure: err => onError(err),
        mfaRequired: (challengeName, challengeParameters) => {
            onError.handleMFAStep(challengeName, challengeParameters, confirmationCode => {
                cognitoUser.sendMFACode(confirmationCode, {
                    onSuccess: result => onSuccess(result),
                    onFailure: err => onError(err),
                });
            });
        },
    });
};
